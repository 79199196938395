import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/Home', // 将根路径重定向到 '/Home'
    component: () => import('../views/index.vue'),
    children: [
      {
        path: '/Home',
        name: 'home',
        component: () => import('../views/homepage.vue'),
      },
      {
        path: '/quantitative',
        name: 'quantitative',
        component: () => import('../views/AI.vue'),
      },
      {
        path: '/Customer',
        name: 'customer',
        component: () => import('../views/customer.vue'),
      },

      {
        path: '/About',
        name: 'about',
        component: () => import('../views/aboutUs.vue'),
      },
      {
        path: '/trading',
        name: 'trading',
        component: () => import('../views/trading.vue'),
      },
      {
        path: '/expectations',
        name: 'expectations',
        component: () => import('../views/expectations.vue'),
      }
    ]
  },
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
